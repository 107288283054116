<template>
  <div class="pcm-wrapper">
    <div class="logoBox">
      <div class="logo"></div>
      <div class="appName"></div>
    </div>
    <div class="contactBox">
      <!-- <div class="webIcon" @click="toWeb"></div> -->
      <div class="telegram" v-if="cTg" @click="jumpUrl(cTg)"></div>
      <div class="potato" v-if="cPotato" @click="jumpUrl(cPotato)"></div>
      <div class="business" v-if="cBusiness" @click="jumpUrl(cBusiness)"></div>
      <div class="channel" v-if="cChannel" @click="jumpUrl(cChannel)"></div>
    </div>

    <div class="infoBox">
      <div class="btnBox">
        <div
          class="iosBtn"
          :style="{ backgroundImage: 'url(' + iosBtnImg + ')' }"
          @click="viewQr('ios')"
        ></div>
        <div
          class="androidBtn"
          :style="{ backgroundImage: 'url(' + androidBtnImg + ')' }"
          @click="viewQr('android')"
        ></div>
        <div class="desc">推荐使用uc浏览器扫码</div>
      </div>
      <img v-if="iosQr" class="iosQrcode" :src="qrImg" alt="" />
      <img v-if="androidQr" class="qrcode" :src="qrImg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["qrImg", "os"],
  data() {
    return {
      iosQr: false,
      androidQr: false,
      iosBtnImg: require("@/assets/images/pc/iosBtn.png"),
      androidBtnImg: require("@/assets/images/pc/androidBtn.png"),
      cTg: "",
      cPotato: "",
      cBusiness: "",
      cChannel: "",
    };
  },
  mounted() {},
  destroyed() {
    clearTimeout(this.et);
  },
  methods: {
    getApkInfo(type) {
      this.$emit("getApkInfo", type);
    },
    setConfigs(configs) {
      configs.forEach((item) => {
        if (item.configType === "tg_group") {
          this.cTg = item.link;
        } else if (item.configType === "potato_group") {
          this.cPotato = item.link;
        } else if (item.configType === "sw_cooperate") {
          this.cBusiness = item.link;
        } else if (item.configType === "qd_cooperate") {
          this.cChannel = item.link;
        }
      });
    },
    jumpUrl(url) {
      window.open(url);
    },
    viewQr(type) {
      if (type === "ios") {
        this.androidQr = false;
        this.androidBtnImg = require("@/assets/images/pc/androidBtn.png");
        this.iosQr = !this.iosQr;
        if (this.iosQr) {
          this.iosBtnImg = require("@/assets/images/pc/iosBtnFocus.png");
        } else {
          this.iosBtnImg = require("@/assets/images/pc/iosBtn.png");
        }
      } else {
        this.iosQr = false;
        this.iosBtnImg = require("@/assets/images/pc/iosBtn.png");
        this.androidQr = !this.androidQr;
        if (this.androidQr) {
          this.androidBtnImg = require("@/assets/images/pc/androidBtnFocus.png");
        } else {
          this.androidBtnImg = require("@/assets/images/pc/androidBtn.png");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url("../../../assets/images/pc/bg.jpg") no-repeat center;
  background-size: 100% 100%;
  background-size: cover;
  .logoBox {
    position: fixed;
    top: 60px;
    left: 72px;
    color: #fff;
    font-size: 39px;
    text-align: center;
    z-index: 10;
    .logo {
      width: 143px;
      height: 143px;
      background: url("../../../assets/images/pc/logo.png") no-repeat center;
      background-size: 100% 100%;
    }
    .appName {
      height: 65px;
      background: url("../../../assets/images/pc/hls.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .contactBox {
    position: fixed;
    right: 80px;
    top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .webIcon {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .business {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/business.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 24px;
      cursor: pointer;
    }
    .channel {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/channel.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }

  .infoBox {
    position: fixed;
    bottom: 17.7%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    height: 643.6px;
    width: 946.6px;
    background: url("../../../assets/images/pc/breakingText.png") no-repeat
      center;
    background-size: 100% 100%;
    .qrcode {
      width: 164px;
      height: 164px;
      position: absolute;
      left: 400px;
      top: 330px;
      background: #fff;
    }
    .iosQrcode {
      width: 164px;
      height: 164px;
      position: absolute;
      left: 655px;
      top: 360px;
      background: #fff;
    }
    .btnBox {
      height: 127px;
      width: 540px;
      position: relative;
      top: 510px;
      left: 350px;
      .desc {
        text-align: center;
        font-size: 15px;
        color: #fff;
        position: absolute;
        bottom: 30px;
        left: 20px;
        letter-spacing: 1px;
        transform: rotate(7.5deg);
      }
      .iosBtn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 311.4px;
        height: 103.3px;
        // background: url("../../../assets/images/pc/iosBtn.png") no-repeat
        //   center;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .androidBtn {
        position: absolute;
        left: 0;
        top: 0;
        width: 241px;
        height: 88px;
        background: url("../../../assets/images/pc/androidBtn.png") no-repeat
          center;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
}
</style>
